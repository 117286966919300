import React from 'react';
import { graphql, Link } from 'gatsby';
import logger from 'utils/logger';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import Fade from 'react-reveal/Fade';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import { Menu } from 'components/Navigation';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import { ReactComponent as Speaker } from 'assets/speaker.svg';

import { Container, Row } from 'components/GridSystem';
import {
  CoverAbout,
  BoldStatement
} from 'components/Sections';
import { SectionLabel } from 'components/Text';
import { CallToActionPreFooter } from 'components/CallToActions';

const animationDuration = 600;
const easing = 500;
const transition = css`
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
  &:hover, &:focus {
    transition: all ${easing / 2}ms cubic-bezier(.1,.55,.4,1);
  }
`;

const SectionAbout = styled.section`
  ${tw`
    pb-2
  `};
`;
const SectionFacts = styled.section`
  ${tw`
    pt-8
    pb-2
  `};
`;
const ColImg = styled.div`
  ${tw`
    bg-gray-100
    md:w-2/5
    lg:w-1/2
    relative
  `};
`;
const BgImage = styled.div`
  ${tw`
    absolute
  `};
  top: 0; bottom: 0;
  left: 0; right: 0;
  ${(props) => {
    const { background } = props;
    return [
      background && (background.image && `background-image: url('${background.image.substring(0, background.image.indexOf('?'))}?w=1200&q=90');`),
      background && (background.size ? `background-size: ${background.size};` : `background-size: cover;`),
      background && (background.repeat ? `background-repeat: ${background.repeat};` : `background-repeat: no-repeat;`),
      background && (background.position ? `background-position: ${background.position};` : `background-position: center;`)
    ];
  }};
`;
const ColText = styled.div`
  ${tw`
    py-3
    xl:py-6
    md:w-3/5
    lg:w-1/2
    px-1/12
    md:px-1/12
    xl:px-1/12
  `};
  p {
    ${tw`
      text-md
      lg:text-lg
      leading-normal
      font-light
      tracking-tight
    `};

  }
  ${(props) => {
    const { reverse } = props;
    return [
      reverse ? tw`` : tw``,
    ];
  }};
`;
const RowContainer = styled.div`
  ${tw`
    w-full
    flex
    flex-wrap
    items-stretch
    content-center
    mb-3
    xl:mb-6
  `};
`;

const Title = styled.h1`
  ${tw`
    font-serif
    text-4xl
    leading-tight
    md:text-3xl
    md:leading-tight
    xl:text-hero-lg
    xl:leading-tighter
    xl:tracking-tighter
    xl:ml--0.5
  `};
  ${(props) => {
    const { light } = props;
    return [
      light && tw`font-normal`,
    ];
  }};
  &:after {
    content: "";
    ${tw`
      block
      bg-brand-primary
      my-3
      w-6
      h-4px
      xl:ml-0.5
    `};
  }
`;
const Text = styled(MDXRenderer)`
  ${tw`
    text-brand-primary
  `};
`;

const SectionHow = styled.section`
  ${tw`
    xl:py-4
  `};
`;
const ColHow = styled.div`
  ${tw`
    xl:w-1/3
    xl:px-2
  `};
`;
const ImgHow = styled.div`
  ${tw`
    bg-gray-100
    pb-aspect-ratio-16/9
    mb-3
  `};
`;
const TitleHow = styled.h2`
  ${tw`
    font-light
    pt-2
    md:pt-0
    pl-1
    pr-2
    text-4xl
    tracking-tight
    leading-tight
    font-serif
    xl:text-5xl
    xl:tracking-tight
  `};
  span {
    ${tw`
      block
      leading-tight
      xl:leading-tight
      font-serif
    `};
  }
  &:after {
    content: "";
    ${tw`
      block
      bg-brand-primary
      my-3
      w-6
      h-4px
    `};
  }
`;

const TextHow = styled.div`
  ${tw`
    font-light
    pl-1
    pr-2
    text-md
    leading-normal
  `};
`;

const Heading = styled.h1`
  ${tw`
    font-light
    font-serif
    xl:text-5xl
    xl:leading-tighter
  `};
`;
const Lead = styled.span`
  ${tw`
    font-normal
  `};
`;
const ColLabelHow = styled.div`
  ${tw`
    text-center
    w-full
    pb-2
  `};
`;
const ColCenter = styled.div`
  ${tw`
    text-center
    w-full
  `};
`;

const ColFacts = styled.div`
  ${tw`
    text-center
    w-full
    xl:px-1/12
  `};
`;
const ColFact = styled.div`
  ${tw`
    text-center
    xl:w-1/3
    xl:py-3
  `};
`;
const ColPronunciation = styled.div`
  ${tw`
    text-center
    xl:w-full
    pt-3
    mb-1
  `};
`;
const ColSpeaker = styled.div`
  ${tw`
    text-center
    xl:w-full
    mb-1
  `};
  svg {
    ${tw`
      fill-current
      stroke-current
      relative
      h-2
      cursor-pointer
      opacity-60
    `}
    ${transition}
    &:hover,
    &:focus {
      ${tw`
        opacity-100
      `}
    }
  }
`;
const FactNumber = styled.div`
  * {
    ${tw`
      font-serif
      font-light
      xl:text-superhero-2xl
      xl:tracking-tighter
      xl:leading-tighter
    `};
  }
  strong {
    ${tw`
      font-bold
    `};
  }
`;
const FactLabel = styled.div`
  ${tw`
    font-light
    xl:text-2xl
  `};
`;
const TextLogo = styled.span`
  ${tw`
    font-bold
  `};
`;
const TextPhonetic = styled.span`
  ${tw`
    font-serif
    font-light
    mx-0.25
  `};
`;
const SectionApproach = styled(Link)`
  ${tw`
    mt-3
    block
    bg-gray-500
    mb-0
  `};
  & * {
    ${tw`
      text-white
    `};
  }
  & span {
    ${tw`
      text-gray-300
    `};
  }
  ${transition};
  &:hover,
  &:focus {
    ${tw`
      bg-brand-primary-700
      text-white
    `};
    & span {
      ${tw`
        text-white
      `};
    }
    & span > span {
      &:before {
        content: "";
        ${tw`
          w-1
          mx-0.5
        `};
      }
    }
  }
`;
const CTAMore = styled.span`
  ${tw`
    font-semibold
    mx-0.25
    relative
    tracking-wider
    uppercase
    text-md
  `};
  & span {
    ${tw`
      inline-block
    `};
    &:before {
      content: "";
      ${transition};
      ${tw`
        relative
        mx-1
        inline-block
        bg-gray-500
        mb-4px
        w-8
        h-2px
      `};
    }
  }
`;
const Article = styled.article`
  ${tw`
    bg-white
    relative
    mt-vh
    z-1
  `};
`;

class Page extends React.Component {
  render () {
    logger.template(__filename, '>', 'render()');
    const {
      data,
      location
    } = this.props;
    logger.gql(data);
    const siteTitle = data.site.siteMetadata.title;
    const {
      title,
      fields
    } = data.contentfulPage;
    const { blocks } = fields;

    const facts = blocks && blocks[1];
    const how = blocks && blocks[blocks.length - 1];
    logger.debug(`how`, blocks.length, how)
    logger.templateEnd();
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Contact" />
        <Menu colorScheme={`dark`} />
        <CoverAbout
          bgColor={`gray-800`}
          label={title}
          intro={blocks[0].titleRich && blocks[0].titleRich.childMdx.rawBody}
          content={blocks[0].content && blocks[0].content.childMdx.body}
          background={{
            image: blocks[0].featuredImage && blocks[0].featuredImage.cover.fixed.src,
            video: blocks[0].featuredImage && (blocks[0].featuredImage.videoLoop && blocks[0].featuredImage.videoLoop.file.url),
          }}
        />
        <Article>
          {/*
          <SectionFacts>
            <Container>
              <Row>
                <ColCenter>
                  <SectionLabel label={title} />
                </ColCenter>
                <ColFacts>
                  <Row>
                    { facts.blocks && facts.blocks.map((block, i) => {
                      const {
                        id,
                        titleRich,
                        content
                      } = block;
                      const reverse = (i + 1) % 2 === 0 && true;
                      return (
                        <ColFact key={id}>
                          <FactNumber>
                            <MDXRenderer>
                              {titleRich && titleRich.childMdx.body}
                            </MDXRenderer>
                          </FactNumber>
                          <FactLabel>
                            <MDXRenderer>
                              {content && content.childMdx.body}
                            </MDXRenderer>
                          </FactLabel>
                        </ColFact>
                      )
                    })}
                  </Row>
                  <Row>
                    <ColPronunciation>
                      <TextLogo>THINK</TextLogo>
                      <span> (</span>
                      <TextPhonetic>/θɪŋk/</TextPhonetic>
                      <span>) + </span>
                      <TextLogo>LO</TextLogo>
                      <span> (</span>
                      <TextPhonetic>/loʊ/</TextPhonetic>
                      <span>)</span>
                    </ColPronunciation>
                    <ColSpeaker>
                      <Speaker />
                    </ColSpeaker>
                  </Row>
                </ColFacts>
              </Row>
            </Container>
          </SectionFacts>
          */}
          <SectionAbout>
            <Container fluid>
              { blocks && blocks.map((block, i) => {
                const {
                  content,
                  id,
                  blocks,
                  preTitle,
                  title,
                  titleRich,
                  featuredImage
                } = block;
                if (i < 2 || blocks) {
                  return (``)
                }
                const reverse = (i + 1) % 2 === 0 && true;
                return (
                  <RowContainer key={id}>
                    <Row reverse={!reverse}>
                      <ColText reverse={!reverse}>
                        {preTitle && (
                          <Fade left duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                            <SectionLabel label={preTitle} />
                          </Fade>
                        )}
                        <Fade top duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                          <Title>{titleRich ? titleRich.childMdx.rawBody : title}</Title>
                        </Fade>
                        {content && (
                          content.childMdx && (
                            <Fade bottom duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                              <MDXProvider
                                components={{
                                  h1: props => <Heading {...props} />,
                                  Lead: props => <Lead {...props} />
                                }}
                              >
                                <MDXRenderer>
                                  {content.childMdx.body}
                                </MDXRenderer>
                              </MDXProvider>
                            </Fade>
                          )
                        )}
                      </ColText>
                      <ColImg>
                        <BgImage background={{ image: featuredImage && featuredImage.cover.fixed.src }}/>
                      </ColImg>
                    </Row>
                  </RowContainer>
                );
              })}
            </Container>
          </SectionAbout>
          <SectionHow>
            <Container>
              <Row>
                <ColLabelHow>
                  <SectionLabel label={`How we work`} />
                </ColLabelHow>
                { how.blocks && how.blocks.map((block, i) => {
                  const {
                    id,
                    titleRich,
                    content
                  } = block;
                  const reverse = (i + 1) % 2 === 0 && true;
                  return (
                    <ColHow key={id}>
                      {/* 
                      <ImgHow />
                       */}
                      <TitleHow>
                        <MDXRenderer>
                          {titleRich && titleRich.childMdx.body}
                        </MDXRenderer>
                      </TitleHow>
                      <TextHow>
                        <MDXRenderer>
                          {content && content.childMdx.body}
                        </MDXRenderer>
                      </TextHow>
                    </ColHow>
                  )
                })}
              </Row>
            </Container>
          </SectionHow>
          {/* 
          <SectionApproach to={`/approach`}>
            <Container fluid>
              <Row>
                <ColImg>
                </ColImg>
                <ColText>
                  <SectionLabel label={`Approach`} />
                  <Title light>{`It's not what to do but how can it impact your business.`}</Title>
                  { blocks && (
                    blocks[0].content && (
                      blocks[0].content.childMdx && (
                        <Fade right duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                          <MDXProvider
                            components={{
                              h1: props => <Heading {...props} />,
                              Lead: props => <Lead {...props} />
                            }}
                          >
                            <MDXRenderer>
                              {blocks[0].content.childMdx.body}
                            </MDXRenderer>
                          </MDXProvider>
                        </Fade>
                      )
                    )
                  )}
                  <CTAMore>
                    Learn More
                    <span>About our approach</span>
                  </CTAMore>
                </ColText>
              </Row>
            </Container>
          </SectionApproach>
          */}
        </Article>
        <CallToActionPreFooter
          bgColor={`gray-700`}
          title={`Have a challenge you’d like us to explore?`}
          callToAction={`Get in touch!`}
          uri={``}
          to={`/contact`}
          utm={{
            campaign: `t`
          }}
        />
      </Layout>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage( slug: { eq: $slug } ){
      ...Page
    }
  }
`;
